// Angular core modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AngularTokenService } from 'angular-token';

// App components
import { DashboardComponent } from './dashboard/dashboard.component';

export const routes: Routes = [
    {
        path: '',
        component: DashboardComponent,
        canActivate: [AngularTokenService]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class DashboardRoutingModule { }
