import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'clemos-lead-self-declaration',
  templateUrl: './lead-self-declaration.component.html',
  styleUrls: ['./lead-self-declaration.component.scss']
})
export class LeadSelfDeclarationComponent implements OnInit {
  form = new FormGroup({});
  lead;

  fields: FormlyFieldConfig[] = [
    {
      key: 'propertyInfos.purpose',
      type: 'input',
      templateOptions: {
        label: 'PROPERTY_INFOS.PURPOSE',
        required: true,
      },
    },
  ];

  constructor() { }

  ngOnInit() {
  }

}
