// Angular core modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// App components
import { ReportsComponent } from './reports/reports.component';

export const routes: Routes = [
    {
        path: '',
        component: ReportsComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class ReportsRoutingModule { }
