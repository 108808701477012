import { Component, OnInit, Input, OnDestroy, HostBinding } from '@angular/core';
import { MessageBoxMessage, Coach } from '@clemos/models';
import { Observable, Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '@clemos/jupiter/reducers';

@Component({
  selector: 'clemos-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnDestroy {

  @Input() messages: MessageBoxMessage[];

  currentUser: Coach;
  currentUserSubscription: Subscription;


  constructor(private store: Store<fromRoot.State>) {
    this.currentUserSubscription = this.store.pipe(select(fromRoot.getCurrentUser)).subscribe(
      user => {
        this.currentUser = user;
      }
    );
  }

  @HostBinding('class.scrollable')
  @HostBinding('class.flex-grow')

  isSender(sender): boolean {
    return (sender[sender.type].id === this.currentUser.id);
  }

  isCustomer(sender): boolean {
    return (sender.type === 'customer');
  }

  isShowPhoto(sender): boolean {
    return (this.isSender(sender) || this.isCustomer(sender));
  }

  ngOnDestroy(): void {
    this.currentUserSubscription.unsubscribe();
  }

}
