import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MessageBoxConversationService } from '@clemos/resources';


@Injectable()
export class ConversationResolver implements Resolve<any> {

    constructor(private conversationService: MessageBoxConversationService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        return this.conversationService
            .get({ id: route.params.id })
            .pipe(map(res => res['conversation']));
    }
}
