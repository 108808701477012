import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

import * as LayoutActions from '@clemos/jupiter/core/actions/layout.actions';
import * as fromRoot from '@clemos/jupiter/reducers';

import { MessageBoxConversation } from '@clemos/models';
import { HttpClient } from '@angular/common/http';
import { MessageBoxConversationService } from '@clemos/resources';
import { map } from 'rxjs/operators';

@Component({
  selector: 'clemos-conversations-overvew',
  templateUrl: './conversations-overview.component.html',
  styleUrls: ['./conversations-overview.component.scss']
})
export class ConversationsOverviewComponent implements OnInit, OnDestroy {

  conversations$: Observable<MessageBoxConversation[]>;
  currentPage: Number;
  totalCount: Number;

  constructor(
    private router: Router,
    private conversationService: MessageBoxConversationService,
    private http: HttpClient
  ) { }

  @HostBinding('class.layout-chat')

  getConversationsList() {
    this.conversations$ = this.conversationService.query().pipe(
      map(res => res['messageBox/conversations'])
    );
      // .subscribe(res => {
      //   this.conversations = res['message_box/conversations'];
      //   if (this.router.url === '/inside/message-box') {
      //     if (this.conversations.length > 0) {
      //       this.router.navigate(['inside', 'message-box', this.conversations[0].id]);
      //     }
      //   }
      // });
  }

  ngOnInit() {
    this.currentPage = 1;
    this.getConversationsList();
  }

  ngOnDestroy() {
  }
}
