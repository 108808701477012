import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "name" })
export class NamePipe implements PipeTransform {
  transform(profile: any) {
    return profile.firstName + " " + profile.lastName;
  }
}

export class FullNamePipe implements PipeTransform {
  transform(profile: any) {
    return profile.firstName + " " + profile.lastName;
  }
}
