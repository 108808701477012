import { Component, Input } from '@angular/core';
import { Lead } from '@clemos/models';

@Component({
  selector: 'clemos-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.scss']
})
export class LeadListComponent {

  @Input() leads: Lead[];

  constructor() { }

}
