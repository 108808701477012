import { Action } from '@ngrx/store';
import { Coach } from '@clemos/models';

export enum CurrentUserActionTypes {
    LoadCurrentUser = '[Current User] Set current user profile'
}

export class LoadCurrentUser implements Action {
    readonly type = CurrentUserActionTypes.LoadCurrentUser;

    constructor(public payload: Coach) { }
}

export type CurrentUserActionsUnion =
    LoadCurrentUser;
