import {
    LayoutActionTypes,
    LayoutActionsUnion,
} from '@clemos/jupiter/core/actions/layout.actions';

export interface State {
    showSidenav: boolean;
    showAside: boolean;
    showQuickview: boolean;
}

const initialState: State = {
    showSidenav: false,
    showAside: false,
    showQuickview: false
};

export function reducer(
    state: State = initialState,
    action: LayoutActionsUnion
): State {
    switch (action.type) {
        case LayoutActionTypes.CloseSidenav:
            return {
                ...state,
                showSidenav: false,
            };

        case LayoutActionTypes.OpenSidenav:
            return {
                ...state,
                showSidenav: true,
            };

        case LayoutActionTypes.CloseQuickview:
            return {
                ...state,
                showQuickview: false,
            };

        case LayoutActionTypes.OpenQuickview:
            return {
                ...state,
                showQuickview: true,
            };
        case LayoutActionTypes.ToggleAside:
            return {
                ...state,
                showAside: !state.showAside,
            };

        default:
            return state;
    }
}

export const getShowSidenav = (state: State) => state.showSidenav;
export const getShowAside = (state: State) => state.showAside;
export const getShowQuickview = (state: State) => state.showQuickview;
