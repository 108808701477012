import { Component, OnInit, Input } from '@angular/core';
import { Lead, Note } from '@clemos/models';
import { Observable } from 'rxjs';
import { NoteService } from '@clemos/resources';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'clemos-lead-notes',
  templateUrl: './lead-notes.component.html',
  styleUrls: ['./lead-notes.component.scss']
})
export class LeadNotesComponent implements OnInit {

  @Input() lead: Lead;
  notes$: Observable<Note[]>;

  newNote: string;

  constructor(
    private noteService: NoteService
  ) { }

  getNotes() {
    this.notes$ = this.noteService.query({}, { params: { contextId: this.lead.id, contextType: 'Lead' } })
      .pipe(map(res => res['notes']));
  }

  addNote() {
    const data = {
      note: {
        body: this.newNote,
        context_id: this.lead.id,
        context_type: 'Lead'
      }
    };
    this.noteService.save(data).pipe(
      tap(() => {
        this.getNotes();
        this.newNote = '';
      })
    ).subscribe();
  }

  ngOnInit() {
    this.getNotes();
  }

}
