import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-currency',
  template: `
    <input currencyMask [formControl]="formControl" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError">
  `,
  host: {
    '[class.d-inline-flex]': 'to.addonLeft || to.addonRight',
    '[class.custom-file]': 'to.addonLeft || to.addonRight',
  },
})
export class FormlyFieldCurrency extends FieldType {
  get type() {
    return this.to.type || 'text';
  }
}
