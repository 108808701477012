// Angular core modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// App components
import { InvoicesComponent } from './invoices/invoices.component';

export const routes: Routes = [
    {
        path: '',
        component: InvoicesComponent
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class InvoicesRoutingModule { }
