import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from 'ng-restly';
// import { environment } from '@env/environment';
import { RequestOptions, ResponseContentType } from '@angular/http';
// import { getFileNameFromResponseContentDisposition, saveFile } from '@app/core/helpers/file-download-helper';
// import { DownloadHelperService } from '../components/download-helper/download-helper.service';

@Injectable({
    providedIn: 'root'
})
export class DocumentService extends ResourceService {

    resourceUrl = 'api/documents/:id';

    constructor(protected http: HttpClient,
        // private downloadHelperService: DownloadHelperService
    ) {
        super(http);
        this.url = this.resourceUrl;
    }

    download(fileId: string) {
        /*
          Assume you have an endpoint that returns all the posts with their the comments
          Change the url temporarily to that endpoint
        */
        // this.url = environment.apiUrl + '/documents/download?file_id=' + fileId;
        // this.downloadHelperService.download(this.url);

        /*
          Use the method your api require using super from super class ResourceService
          Don't return it just yet, store it in a variable
        */
        // const ret = super.query({}, {params: {fileId: fileId}});
        // Change the url back to default baseUrl
        // this.url = this.resourceUrl;

        // this.http.get(this.url, options).subscribe(res => {
        //     const fileName = getFileNameFromResponseContentDisposition(res);
        //     saveFile(res.blob(), fileName);
        // });

        // Now Return
        // return ret;


        // Process the file downloaded

    }
}
