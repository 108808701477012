import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from 'ng-restly';
// import { environment } from 'projects/clemos-cosmic-ray/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CoachService extends ResourceService {
    constructor(protected http: HttpClient) {
        super(http);
        this.url = 'api/coaches/:id';
    }
}
