import { NgModule } from '@angular/core';
import { SettingsComponent } from './settings/settings.component';
import { SettingsRoutingModule } from './settings-routing.module';

const components = [
  SettingsComponent
];

@NgModule({
  imports: [
    SettingsRoutingModule
  ],
  declarations: components
})
export class SettingsModule { }
