import { Component, OnInit, Input } from '@angular/core';
import { Lead } from '@clemos/models';

@Component({
  selector: 'clemos-lead-quickview',
  templateUrl: './lead-quickview.component.html',
  styleUrls: ['./lead-quickview.component.scss']
})
export class LeadQuickviewComponent implements OnInit {

  @Input() lead: Lead;

  constructor() { }

  ngOnInit() {
  }

}
