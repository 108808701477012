import { Component, OnInit, HostBinding } from '@angular/core';
import { AngularTokenService } from 'angular-token';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import * as LayoutActions from '@clemos/jupiter/core/actions/layout.actions';
import * as fromRoot from '@clemos/jupiter/reducers';

import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { Coach } from '@clemos/models';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'clemos-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {


  faPowerOff = faPowerOff;
  output: any;
  currentUser$: Observable<Coach>;
  private currentUserSubscription: Subscription;

  constructor(
    public tokenService: AngularTokenService,
    public dropdownConfig: NgbDropdownConfig,
    private store: Store<fromRoot.State>) {

    this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));
    this.dropdownConfig.placement = 'bottom-right';
  }

  @HostBinding('class.topbar')

  closeSidenav() {
    this.store.dispatch(new LayoutActions.CloseSidenav());
  }

  openSidenav() {
    this.store.dispatch(new LayoutActions.OpenSidenav());
  }

  signOut() {
    this.tokenService.signOut().subscribe(
      res => {
        setTimeout(function () {
          this.output = res;
        }, 100);
      },
      error => this.output = error
    );
  }

  ngOnInit() {
  }
}
