import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-file',
  template: `
    <div class="file-group file-group-inline">
        <button class="btn btn-primary file-browser" type="button">Browse</button>
        <input type="file" [formControl]="formControl" [formlyAttributes]="field">
    </div>
  `,
})
export class FormlyFieldFile extends FieldType {}
