import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { LeadsRoutingModule } from './leads-routing.module';

import { LeadDetailComponent } from '@clemos/jupiter/leads/containers/lead-detail/lead-detail.component';
import { LeadOverviewComponent } from '@clemos/jupiter/leads/containers/lead-overview/lead-overview.component';

import { LeadResolver } from './resolvers/lead-resolver.service';
import { ComponentsModule } from './components';
import { LeadDealListComponent } from './components/lead-deal-list/lead-deal-list.component';
import { DealDetailComponent } from './containers/deal-detail/deal-detail.component';
import { DealOverviewComponent } from './containers/deal-overview/deal-overview.component';
import { LeadSelfDeclarationComponent } from './components/lead-self-declaration/lead-self-declaration.component';

const CONTAINERS = [
  LeadOverviewComponent,
  LeadDetailComponent,
  DealDetailComponent,
  DealOverviewComponent,
];



@NgModule({
  imports: [
    LeadsRoutingModule,
    SharedModule,
    ComponentsModule
  ],
  declarations: [
    ...CONTAINERS,
  ],
  providers: [
    LeadResolver
  ]
})

export class LeadsModule { }
