import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
    OpenSidenav = '[Layout] Open Sidenav',
    CloseSidenav = '[Layout] Close Sidenav',
    ToggleAside = '[Layout] Toggle Aside',
    OpenQuickview = '[Layout] Open Quickview',
    CloseQuickview = '[Layout] Close Quickview'
}

export class OpenSidenav implements Action {
    readonly type = LayoutActionTypes.OpenSidenav;
}

export class CloseSidenav implements Action {
    readonly type = LayoutActionTypes.CloseSidenav;
}

export class OpenQuickview implements Action {
    readonly type = LayoutActionTypes.OpenQuickview;
}

export class CloseQuickview implements Action {
    readonly type = LayoutActionTypes.CloseQuickview;
}

export class ToggleAside implements Action {
    readonly type = LayoutActionTypes.ToggleAside;
}

export type LayoutActionsUnion =
    OpenSidenav |
    CloseSidenav |
    OpenQuickview |
    CloseQuickview |
    ToggleAside;
