import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from 'ng-restly';

@Injectable({
    providedIn: 'root'
})

export class NoteService extends ResourceService {
    constructor(protected http: HttpClient) {
        super(http);
        this.url = 'api/notes/:id';
    }
}
