import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoicesRoutingModule } from './invoices-routing.module';

const components = [
  InvoicesComponent
];

@NgModule({
  imports: [
    InvoicesRoutingModule
  ],
  declarations: components
})
export class InvoicesModule { }
