import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceService } from 'ng-restly';

@Injectable({
    providedIn: 'root'
})
export class PhoneService extends ResourceService {
    constructor(protected http: HttpClient) {
        super(http);
        this.url = 'api/phones/:id';
    }

    requestVerification() {
        /*
          Assume you have an endpoint that returns all the posts with their the comments
          Change the url temporarily to that endpoint
        */
        this.url = 'api/phones/request_verification';
        /*
           Use the method your api require using super from super class ResourceService
           Don't return it just yet, store it in a variable
         */
        const ret = super.save({ phone: { number: '4369911091138' } });
        // Change the url back to default baseUrl
        this.url =  'api/phones/:id';
        // Now Return
        return ret;
    }

    verify(data: any) {
        this.url = 'api/phones/:id/verify';
        const ret = super.update({ verificationCode: '123' }, {id: data.id});
        this.url = 'api/phones/:id';
        return ret;

    }
}
