import { Component, OnInit, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { Lead } from '@clemos/models';
import { LeadService } from '@clemos/resources';


@Component({
  selector: 'clemos-leads-overview',
  templateUrl: './lead-overview.component.html',
  styleUrls: ['./lead-overview.component.scss']
})
export class LeadOverviewComponent implements OnInit, OnDestroy {

  leads: Lead[];
  currentPage: Number;
  totalCount: Number;
  leadsQuerySubscription: Subscription;

  constructor(
    private leadService: LeadService) {
  }

  getLeadsList() {
    this.leadsQuerySubscription = this.leadService.query().subscribe(res => {
      this.leads = res['leads'];
    });
  }

  ngOnInit() {
    this.currentPage = 1;
    this.getLeadsList();
  }

  ngOnDestroy() {
    this.leadsQuerySubscription.unsubscribe();
  }
}
