import { NgModule } from '@angular/core';


import { SharedModule } from '@clemos/jupiter/shared/shared.module';
import { LeadDocumentsComponent } from './lead-documents/lead-documents.component';
import { LeadFilterComponent } from './lead-filter/lead-filter.component';
import { LeadFinancialStatementsComponent } from './lead-financial-statements/lead-financial-statements.component';
import { LeadListComponent } from './lead-list/lead-list.component';
import { LeadListItemComponent } from './lead-list-item/lead-list-item.component';
import { LeadMessagesComponent } from './lead-messages/lead-messages.component';
import { LeadNotesComponent } from './lead-notes/lead-notes.component';
import { LeadObjectInfoComponent } from './lead-object-info/lead-object-info.component';
import { LeadQuickviewComponent } from './lead-quickview/lead-quickview.component';
import { LeadTasksComponent } from './lead-tasks/lead-tasks.component';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LeadDealListComponent } from './lead-deal-list/lead-deal-list.component';
import { LeadSelfDeclarationComponent } from './lead-self-declaration/lead-self-declaration.component';
import { LeadLoanAnalysisComponent } from './lead-loan-analysis/lead-loan-analysis.component';
import { LeadPropertyComponent } from './lead-property/lead-property.component';
import { LeadHouseholdComponent } from './lead-household/lead-household.component';
import { LeadBorrowersComponent } from './lead-borrowers/lead-borrowers.component';



export const COMPONENTS = [
    LeadDealListComponent,
    LeadDocumentsComponent,
    LeadFilterComponent,
    LeadFinancialStatementsComponent,
    LeadListComponent,
    LeadListItemComponent,
    LeadMessagesComponent,
    LeadNotesComponent,
    LeadObjectInfoComponent,
    LeadQuickviewComponent,
    LeadTasksComponent,
    LeadSelfDeclarationComponent,
    LeadLoanAnalysisComponent,
    LeadPropertyComponent,
    LeadHouseholdComponent,
    LeadBorrowersComponent,
];

@NgModule({
    imports: [
        SharedModule,
        RouterModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
})
export class ComponentsModule { }
