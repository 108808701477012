// Angular core modules
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// App modules
import { InflectorInterceptor } from '@clemos/http';


@NgModule({
    imports: [
        HttpClientModule,
    ],
    exports: [
        HttpClientModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InflectorInterceptor,
            multi: true
        }
    ]
})
export class AppApiModule {}
