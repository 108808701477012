import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '@clemos/jupiter/reducers';

import { MessageBoxMessage, Coach, Lead } from '@clemos/models';
import { MessageBoxMessageService } from '@clemos/resources';

@Component({
  selector: 'clemos-lead-messages',
  templateUrl: './lead-messages.component.html',
  styleUrls: ['./lead-messages.component.scss']
})
export class LeadMessagesComponent implements OnInit {

  @Input() lead: Lead;
  messages$: Observable<MessageBoxMessage[]>;
  currentUser: Coach;
  newMessage: string;

  constructor(
    private messageService: MessageBoxMessageService,
    private store: Store<fromRoot.State>
  ) {
    this.store.pipe(select(fromRoot.getCurrentUser)).pipe(
      tap(user => this.currentUser = user)
    ).subscribe();
  }

  getMessages() {
    this.messages$ = this.messageService.query({}, { params: { } })
      .pipe(
        map(res => res['messageBox/messages']));
  }

  addMessage() {
    const data = {
      message: {
        body: this.newMessage
      }
    };
    this.messageService.save(data).pipe(
      tap(() => {
        this.getMessages();
        this.newMessage = '';
      })
    ).subscribe();
  }

  isMe(message) {
    return message.participant.user.id === this.currentUser.id;
  }

  ngOnInit() {
    this.getMessages();
  }

}
