import { Component, HostBinding, OnInit } from '@angular/core';
import {
  faTachometerAlt,
  faBriefcase,
  faComments,
  faUsers,
  faSignal,
  faHeadset,
  faHotel,
  faFileInvoice,
  faCogs
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'clemos-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {

  menuItems = [
    {
      name: 'Dashboard',
      path: '/inside/dashboard',
      icon: faTachometerAlt
    },
    {
      name: 'Geschäft',
      path: ['leads'],
      icon: faBriefcase
    },
    {
      name: 'Nachrichten',
      path: '/inside/message-box',
      icon: faComments
    },
    {
      name: 'Kunden',
      path: '/inside/customers',
      icon: faUsers
    },
    {
      name: 'Coaches',
      path: '/inside/coaches',
      icon: faHeadset
    },
    {
      name: 'Partners',
      path: '/inside/institutions',
      icon: faHotel

    },
    {
      name: 'Rechnungen',
      path: '/inside/invoices',
      icon: faFileInvoice
    },
    {
      name: 'Report',
      path: '/inside/reports',
      icon: faSignal
    },
    {
      name: 'Einstellungen',
      path: '/inside/settings',
      icon: faCogs
    }
  ];

  constructor() { }

  @HostBinding('class.sidebar')
  @HostBinding('class.sidebar-expand-lg')
  @HostBinding('class.sidebar-iconic')

  @HostBinding('attr.debug-id')


  ngOnInit() {
  }

}
