import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AngularTokenService, SignInData, AuthData } from 'angular-token';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'clemos-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  signInForm: FormGroup;
  signInData: SignInData = <SignInData>{};
  output: any;
  authData: AuthData;

  constructor(
    public tokenService: AngularTokenService,
    private formBuild: FormBuilder,
    private router: Router
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.authData = this.tokenService.currentAuthData;
  }

  createForm() {
    this.signInForm = this.formBuild.group({
      login: ['coach-1@ikondirekt.com', Validators.required],
      password: ['12345678', Validators.required]
    });
  }

  onSubmit() {

    this.output = null;

    this.signInData.login = this.signInForm.value.login;
    this.signInData.password = this.signInForm.value.password;

    this.tokenService.signIn(this.signInData).subscribe(
      res => {
        this.signInData = <SignInData>{};
        this.output = res;
        this.authData = this.tokenService.currentAuthData;
        // this.router.navigateByUrl(localStorage.getItem('redirectTo'));
        this.router.navigate(['inside', 'dashboard']);
      }, error => {
        this.signInData = <SignInData>{};
        this.output = error;
      }
    );
  }
}
