import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Coach } from '@clemos/models';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'clemos-couches',
  templateUrl: './coach-list.component.html',
  styleUrls: ['./coach-list.component.scss']
})
export class CoachListComponent implements OnInit, OnDestroy {

  coaches: Coach[];
  loading: boolean;
  faPlus = faPlus;

  private querySubscription: Subscription;

  public rows = [
    { name: 'Austin', gender: 'Male', company: 'Swimlane' },
    { name: 'Dany', gender: 'Male', company: 'KFC' },
    { name: 'Molly', gender: 'Female', company: 'Burger King' },
  ];
  columns = [
    { prop: 'name' },
    { name: 'Gender' },
    { name: 'Company' }
  ];

  constructor(
  ) { }


  ngOnInit() {
    // this.querySubscription = this.service.findAll()
    //   .subscribe(result => {
    //     console.log('coach result', result.coaches);
    //     this.coaches = result;
    //   });
  }

  ngOnDestroy() {
    // this.querySubscription.unsubscribe();
  }
}
