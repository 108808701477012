import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MessageListComponent } from './message-list/message-list.component';
import { MessageListItemComponent } from './message-list-item/message-list-item.component';

import { SharedModule } from '@clemos/jupiter/shared/shared.module';
import { ConversationListComponent } from './conversation-list/conversation-list.component';
import { ConversationListItemComponent } from './conversation-list-item/conversation-list-item.component';
import { MessageFooterComponent } from './message-footer/message-footer.component';

export const COMPONENTS = [
    ConversationListComponent,
    ConversationListItemComponent,
    MessageListComponent,
    MessageListItemComponent,
    MessageFooterComponent
];

@NgModule({
    imports: [
        SharedModule,
        RouterModule
    ],
    declarations: COMPONENTS,
    exports: COMPONENTS,
})
export class ComponentsModule { }
