import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import emailMask from "text-mask-addons/dist/emailMask";

@Component({
  selector: "formly-field-currency",
  template: `
    <input [textMask]="{mask: mask}" [formControl]="formControl" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError">
  `,
  host: {
    "[class.d-inline-flex]": "to.addonLeft || to.addonRight",
    "[class.custom-file]": "to.addonLeft || to.addonRight"
  }
})
export class FormlyFieldEmail extends FieldType {
  mask = emailMask;
}
