import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clemos-lead-documents',
  templateUrl: './lead-documents.component.html',
  styleUrls: ['./lead-documents.component.scss']
})
export class LeadDocumentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
