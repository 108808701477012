import { Component, OnInit, Input } from '@angular/core';
import { Lead } from '@clemos/models';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import {
  BooleanOptionsService,
  StatesOptionsService,
  YearOptionsService,
  MortgageOptionsService
} from '@clemos/options';

@Component({
  selector: 'clemos-lead-loan-analysis',
  templateUrl: './lead-loan-analysis.component.html',
  styleUrls: ['./lead-loan-analysis.component.scss']
})
export class LeadLoanAnalysisComponent implements OnInit {

  @Input() lead: Lead;

  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [
    {
      key: 'estate.purpose',
      type: 'select',
      templateOptions: {
        label: 'LEAD_FORM.PROPERTY.PURPOSE.LABEL',
        required: true,
        options: this.mortgageOptionsService.get('property-purpose')
      }
    },
    {
      key: 'estate.estateType',
      type: 'select',
      templateOptions: {
        label: 'LEAD_FORM.PROPERTY.PROPERTY_TYPE.LABEL',
        required: true,
        options: this.mortgageOptionsService.get('property-type')
      }
    },
    {
      key: 'estate.stateCode',
      type: 'select',
      templateOptions: {
        label: 'LEAD_FORM.PROPERTY.STATE_CODE.LABEL',
        required: true,
        options: this.stateOptionsService.get(),
        valueProp: 'code',
        labelProp: 'name'
      }
    },
    {
      key: 'estate.withBroker',
      type: 'select',
      templateOptions: {
        label: 'LEAD_FORM.PROPERTY.WITH_BROKER.LABEL',
        options: this.booleanOptionsService.get(),
        required: true
      }
    },
    {
      key: 'estate.purchaseCost',
      type: 'input',
      templateOptions: {
        label: 'LEAD_FORM.PROPERTY.PURCHASE_COST.LABEL',
        required: true,
      },
    },
    {
      key: 'estate.constructionCost',
      type: 'currency',
      templateOptions: {
        label: 'Baukosten'
      },
      // hideExpression: () => !this.formControl.showConstructionCost
    },
    {
      key: 'estate.otherPurchaseCost',
      type: 'currency',
      templateOptions: {
        label: 'Sonstige Erwerbskosten(z.B. Aufschliessung, Kanalanschuluss)'
      },
      // hideExpression: () => !this.formControl.showOtherPurchaseCost
    },
    {
      key: 'estate.debtRestructuringCost',
      type: 'currency',
      templateOptions: {
        label: 'Umschuldung'
      },
      // hideExpression: () => !this.formControl.showDebtRestructuringCost
    },
    {
      key: 'estate.furnitureCost',
      type: 'currency',
      templateOptions: {
        label: 'Geplante Eirichtungskosten'
      },
      // hideExpression: () => !this.formControl.showFurnitureCost
    },
    {
      key: 'estate.renovationCost',
      type: 'currency',
      templateOptions: {
        label: 'Kosten für Umbau / Sanierung'
      },
      // hideExpression: () => !this.formControl.showRenovationCost
    },
    {
      key: 'estate.upgradingCost',
      type: 'currency',
      templateOptions: {
        label: 'Kosten für Zu- und Ausbau'
      },
      // hideExpression: () => !this.formControl.showUpgradingCost
    },
    {
      key: 'estate.landscapingCost',
      type: 'currency',
      templateOptions: {
        label: 'Kosten für Gartengestaltung'
      },
      // hideExpression: () => !this.formControl.showLandscapingCost
    },
    {
      key: 'estate.enclosureCost',
      type: 'currency',
      templateOptions: {
        label: 'Kosten für Einfriedung'
      },
      // hideExpression: () => !this.formControl.showEnclosureCost
    },
    {
      key: 'loan.downPayment',
      type: 'currency',
      templateOptions: {
        label: 'Eigenmittel',
        description: 'Zusatzliche Text'
      }
    },
    {
      key: 'loan.loanTerm',
      type: 'select',
      templateOptions: {
        label: 'Laufzeit (verhandelbar)',
        options: this.yearOptionsService.get()
      }
    }
  ];
  constructor(
    private stateOptionsService: StatesOptionsService,
    private yearOptionsService: YearOptionsService,
    private booleanOptionsService: BooleanOptionsService,
    private mortgageOptionsService: MortgageOptionsService
  ) { }

  ngOnInit() {
  }

}
