import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Bank } from '@clemos/models';
import { faHotel, faUsers } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'clemos-institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.scss']
})
export class InstitutionsComponent implements OnInit, OnDestroy {

  banks: Bank[];
  loading: boolean;
  faHotel = faHotel;
  faUsers = faUsers;

  private querySubscription: Subscription;

  constructor(
  ) { }

  ngOnInit() {
    // this.querySubscription = this.service.findAll()
      // .subscribe(result => this.banks = result);
  }

  ngOnDestroy() {
    // this.querySubscription.unsubscribe();
  }
}
