// Angular core modules
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyCustomModule } from '@clemos/formly-custom';

import { NgxAutosizeModule } from '@bravobit/ngx-autosize';



@NgModule({
    imports: [
        FormsModule,
        NgxAutosizeModule,
        ReactiveFormsModule,
        FormlyModule.forRoot(),
        FormlyCustomModule,
    ],
    exports: [
        FormsModule,
        NgxAutosizeModule,
        FormlyModule
    ]
})
export class AppFormModule { }
