// Angular core modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// App components
import { ConversationsOverviewComponent } from './containers/conversations-overview/conversations-overview.component';
import { ConversationDetailComponent } from './containers/conversation-detail/conversation-detail.component';
import { ConversationResolver } from './resolvers/conversation-resolver.service';

export const routes: Routes = [
    {
        path: '',
        component: ConversationsOverviewComponent,
        children: [
            {
                path: ':id',
                component: ConversationDetailComponent,
                resolve: {
                    conversation: ConversationResolver
                }
            }
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class MessageBoxRoutingModule { }
