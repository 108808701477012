import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StatesOptionsService {
    states = [
        { code: '9', name: 'Wien' },
        { code: '3', name: 'Nieder­österreich' },
        { code: '4', name: 'Ober­österreich' },
        { code: '1', name: 'Burgenland' },
        { code: '2', name: 'Kärnten' },
        { code: '5', name: 'Salzburg' },
        { code: '6', name: 'Steiermark' },
        { code: '7', name: 'Tirol' },
        { code: '8', name: 'Vorarlberg' },
    ];

    get(): Observable<any[]> {
        return of(this.states);
    }
}
