// Angular core modules
import { NgModule } from '@angular/core';

import { AngularTokenModule } from 'angular-token';
import { environment } from '../../environments/environment';

@NgModule({
    imports: [
        AngularTokenModule.forRoot({
            apiBase: environment.apiUrl,
            apiPath: 'auth',
            signInPath: 'sign_in',
            signInRedirect: 'auth/sign-in',
            signOutPath: 'sign_out',
            validateTokenPath: 'validate_token',
            signOutFailedValidate: true
        }),
    ],
    exports: [
        AngularTokenModule,
    ],
    providers: [
        AngularTokenModule
    ]
})
export class AppAuthModule { }
