import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AngularTokenService } from 'angular-token';
import { map, tap, mergeMap, catchError, delay, concatMap } from 'rxjs/operators';

@Injectable()
export class AuthResolver implements Resolve<any> {
  constructor(
    private tokenService: AngularTokenService,
    private router: Router
  ) { }

  resolve(): Observable<any> {
    return this.tokenService.validateToken().pipe(
      catchError(err => of(this.router.navigate(['auth', 'sign-in']))),
      map(res => res['data']),
    );
  }
}
