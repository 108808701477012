import { Component, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as fromRoot from '@clemos/jupiter/reducers';
import * as LayoutActions from '@clemos/jupiter/core/actions/layout.actions';

import { Lead } from '@clemos/models';

import { faUser, faMapMarkerAlt, faMoneyBill, faClock} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'clemos-lead-list-item',
  templateUrl: './lead-list-item.component.html',
  styleUrls: ['./lead-list-item.component.scss']
})
export class LeadListItemComponent {

  @Input() lead: Lead;

  faUser = faUser;
  faMapMarkerAlt = faMapMarkerAlt;
  faMoneyBill = faMoneyBill;
  faClock = faClock;

  constructor(private store: Store<fromRoot.State>) { }

  openQuickview() {
    this.store.dispatch(new LayoutActions.OpenQuickview());
  }
}
