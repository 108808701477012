import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LeadOverviewComponent } from '@clemos/jupiter/leads/containers/lead-overview/lead-overview.component';
import { LeadDetailComponent } from '@clemos/jupiter/leads/containers/lead-detail/lead-detail.component';
import { LeadResolver } from './resolvers/lead-resolver.service';
import { DealDetailComponent } from './containers/deal-detail/deal-detail.component';
import { DealOverviewComponent } from './containers/deal-overview/deal-overview.component';

export const routes: Routes = [
  {
    path: '',
    component: LeadOverviewComponent
  },
  {
    path: ':id',
    component: LeadDetailComponent,
    children: [
      {
        path: 'deals',
        component: DealOverviewComponent
      },
      {
        path: 'deals/:dealId',
        component: DealDetailComponent
      },
      {
        path: '',
        redirectTo: 'deals',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    redirectTo: 'inside/leads',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class LeadsRoutingModule { }
