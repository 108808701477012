import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'clemos-lead-financial-statements',
  templateUrl: './lead-financial-statements.component.html',
  styleUrls: ['./lead-financial-statements.component.scss']
})
export class LeadFinancialStatementsComponent implements OnInit {

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
    },
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'text',
      type: 'input',
      templateOptions: {
        label: 'Text',
        placeholder: 'Formly is terrific!',
        required: true,
      }
    }
  ];

  submit() {

  }

  constructor() { }

  ngOnInit() {
  }

}
