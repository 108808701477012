import { Component } from '@angular/core';
import { Store } from '@ngrx/store';

import * as LayoutActions from '@clemos/jupiter/core/actions/layout.actions';
import * as fromRoot from '@clemos/jupiter/reducers';


@Component({
  selector: 'clemos-aside-toggler',
  template: `<button class="aside-toggler" (click)='toggleAside()'></button>
  `,
})
export class AsideTogglerComponent {


  constructor(
    private store: Store<fromRoot.State>
  ) { }


  toggleAside() {
    this.store.dispatch(new LayoutActions.ToggleAside());
  }
}
