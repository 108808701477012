import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clemos-lead-property',
  templateUrl: './lead-property.component.html',
  styleUrls: ['./lead-property.component.scss']
})
export class LeadPropertyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
