import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clemos-lead-household',
  templateUrl: './lead-household.component.html',
  styleUrls: ['./lead-household.component.scss']
})
export class LeadHouseholdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
