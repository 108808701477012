import { NgModule } from '@angular/core';
import { InstitutionsComponent } from './institutions/institutions.component';
import { InstitutionsRoutingModule } from './institutions-routing.module';
import { SharedModule } from '../shared/shared.module';
import { InstitutionComponent } from './institution/institution.component';

const components = [
  InstitutionsComponent,
  InstitutionComponent
];

@NgModule({
  imports: [
    InstitutionsRoutingModule,
    SharedModule
  ],
  declarations: components
})
export class InstitutionsModule { }
