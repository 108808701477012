import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Coach, Lead } from '@clemos/models';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';

import * as fromRoot from '@clemos/jupiter/reducers';
import * as LayoutActions from '@clemos/jupiter/core/actions/layout.actions';
import { map } from 'rxjs/operators';
import { LeadService } from '@clemos/resources';

@Component({
  selector: 'clemos-lead-detail',
  templateUrl: './lead-detail.component.html',
  styleUrls: ['./lead-detail.component.scss']
})
export class LeadDetailComponent implements OnInit {

  lead$: Observable<Lead>;

  constructor(
    private leadService: LeadService,
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>) { }


  openQuickview() {
    this.store.dispatch(new LayoutActions.OpenQuickview());
  }
  getLead() {
    this.lead$ = this.leadService.get({id: this.route.snapshot.params.id}).pipe(map(res => res['lead']));
  }

  ngOnInit() {
    this.getLead();
  }
}
