// Angular core modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// App components
import { InstitutionsComponent } from './institutions/institutions.component';
import { InstitutionComponent } from './institution/institution.component';

export const routes: Routes = [

    {
        path: '',
        component: InstitutionsComponent,
        children: [{
            path: ':id',
            component: InstitutionComponent
        }]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class InstitutionsRoutingModule { }
