// Angular core modules
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

// App modules
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

// App components
import { AppComponent } from './app.component';

// config modules
import { AppApiModule } from './config/app-api.module';
import { AppFormModule } from './config/app-form.module';
import { AppAuthModule } from './config/app-auth.module';
import { AppI18nModule } from './config/app-i18n.module';
import { AppNgrxModule } from './config/app-ngrx.module';
import { AppRoutingModule } from './config/app-routing.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    CoreModule,
    SharedModule,

    AppApiModule,
    AppAuthModule,
    AppFormModule,
    AppI18nModule,
    AppNgrxModule,
    AppRoutingModule,

  ],
  declarations: [
    AppComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
