import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as fromRoot from '@clemos/jupiter/reducers';
import * as LayoutActions from '@clemos/jupiter/core/actions/layout.actions';
import * as CurrentUserActions from '@clemos/jupiter/core/actions/current-user.actions';
import { HttpClient } from '@angular/common/http';
import { Profile, Coach } from '@clemos/models';
import { CurrentUserActionTypes } from '../../actions/current-user.actions';
import { CoachService } from '@clemos/resources';

@Component({
  selector: 'clemos-inside',
  templateUrl: './inside.component.html',
  styleUrls: ['./inside.component.scss']
})
export class InsideComponent implements OnInit, OnDestroy {

  showSidenav$: Observable<boolean>;
  showAside$: Observable<boolean>;
  private currentUser: Coach;
  private currentUserAuth: any;
  private showSidenavSubscription: Subscription;
  private ShowAsideSubscription: Subscription;
  private currentUserSubscription: Subscription;

  constructor(
    private store: Store<fromRoot.State>,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private coachService: CoachService) {
    this.showSidenav$ = this.store.pipe(select(fromRoot.getShowSidenav));
    this.showAside$ = this.store.pipe(select(fromRoot.getShowAside));
    this.currentUser = this.route.snapshot.data['currentUser'];
  }

  @HostBinding('class.sidebar-open') isShowSidenav: boolean;
  @HostBinding('class.aside-open') isShowAside: boolean;

  closeSidenav() {
    this.store.dispatch(new LayoutActions.CloseSidenav());
  }

  setCurrentUser() {
    this.store.dispatch(new CurrentUserActions.LoadCurrentUser(this.currentUser));
  }

  getShowSidenav() {
    this.showSidenavSubscription = this.showSidenav$.subscribe((status) => {
      if (status === true) {
        this.isShowSidenav = true;
      } else {
        this.isShowSidenav = false;
      }
    });
  }

  getShowAside() {
    this.ShowAsideSubscription = this.showAside$.subscribe((status) => {
      if (status === true) {
        this.isShowAside = true;
      } else {
        this.isShowAside = false;
      }
    });
  }

  ngOnInit() {
    this.setCurrentUser();
    this.getShowSidenav();
    this.getShowAside();

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.store.dispatch(new LayoutActions.CloseSidenav());
          // this.store.dispatch(new LayoutActions.CloseAside());
        }
      });
  }

  ngOnDestroy() {
    this.showSidenavSubscription.unsubscribe();
    this.ShowAsideSubscription.unsubscribe();
    this.currentUserSubscription.unsubscribe();
  }
}
