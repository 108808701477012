// Angular core modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// App components
import { CoachListComponent } from './coach-list/coach-list.component';

export const routes: Routes = [
    {
        path: '',
        component: CoachListComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class CoachesRoutingModule { }
