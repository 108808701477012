import { NgModule } from '@angular/core';
import { CoachListComponent } from './coach-list/coach-list.component';
import { CoachesRoutingModule } from './coaches-routing.module';
import { SharedModule } from '../shared/shared.module';

const components = [
  CoachListComponent
];


@NgModule({
  imports: [
    CoachesRoutingModule,
    SharedModule
  ],
  providers: [],
  declarations: components
})
export class CoachesModule {}
