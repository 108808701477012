import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpParams
} from '@angular/common/http';
import { map } from 'rxjs/operators';

// import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs/Observable';
import { catchError, tap } from 'rxjs/operators';

const changeCase = require('change-case');
const camelCaseKeys = require('camelcase-keys');
const snakeCaseKeys = require('snakecase-keys');

@Injectable({
  providedIn: 'root'
})
export class InflectorInterceptor implements HttpInterceptor {

  regexpJsonFile: RegExp = /.json/;

  constructor() { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (req.body && typeof(req.body) !== 'object') {
      req = req.clone({
        body: snakeCaseKeys(JSON.parse(req.body))
      });
    }

    if (req.params) {
      let newParams = req.params;
      newParams.keys().forEach(key => {
        newParams = newParams.append(changeCase.snake(key), newParams.get(key));
        newParams = newParams.delete(key);
      });
      req = req.clone({
        params: newParams
      });
    }

    return next.handle(req).pipe(
      map(
        event => {
          if (event instanceof HttpResponse) {
            // Filter language json files
            if (!this.regexpJsonFile.test(event.url)) {
              event = event.clone({
                body: camelCaseKeys(event.body, { deep: true })
              });
            }
            return event;
          }
        },
        error => {
          // http response status code
          console.log('----response----');
          console.error('status code:');
          console.error(error.status);
          console.error(error.message);
          console.log('--- end of response---');
        }
      )
    );
  }
}
