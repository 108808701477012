import { Component, OnInit, Input } from '@angular/core';
import { Lead, Deal } from '@clemos/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DealService } from '@clemos/resources';

@Component({
  selector: 'clemos-lead-deal-list',
  templateUrl: './lead-deal-list.component.html',
  styleUrls: ['./lead-deal-list.component.scss']
})
export class LeadDealListComponent implements OnInit {

  @Input() lead: Lead;

  deals$: Observable<Deal>;

  constructor(private dealService: DealService) { }

  getDeals() {
    this.deals$ = this.dealService.query({leadId: this.lead.id}).pipe(map(res => res.deals));
  }

  ngOnInit() {
    this.getDeals();
  }

}
