// Core Modules
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { RouterModule } from "@angular/router";

// third-party Modules
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

// App Modules
import {SharedModule} from "../shared/shared.module";


// App Components
import { FooterComponent } from "./components/footer/footer.component";
import { InsideComponent } from "./containers/inside/inside.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { TopbarComponent } from "./components/topbar/topbar.component";

// Utils
import { throwIfAlreadyLoaded } from "./module-import-guard";

import { AuthResolver } from "./resolvers/auth-resolver.service";

const COMPONENTS = [
  InsideComponent,
  FooterComponent,
  SidebarComponent,
  TopbarComponent,
];

const RESOLVERS = [
  AuthResolver
];

const GUARDS = [
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    FontAwesomeModule
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [
    ...RESOLVERS,
    ...GUARDS
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
