import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { SharedModule } from '../shared/shared.module';

const components = [
  DashboardComponent
];


@NgModule({
  imports: [
    DashboardRoutingModule,
    SharedModule
  ],
  declarations: components
})
export class DashboardModule {}
