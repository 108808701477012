// Angular core modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// App components
import { CustomersComponent } from './customers/customers.component';

export const routes: Routes = [
    {
        path: '',
        component: CustomersComponent
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class CustomersRoutingModule { }
