import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NamePipe } from './name.pipe';

const pipes = [
  NamePipe
];


@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: pipes
})
export class ClemosPipesModule {}
