import { Component, ChangeDetectionStrategy, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageBoxConversation } from '@clemos/models';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { select, Store } from '@ngrx/store';
import * as fromRoot from '@clemos/jupiter/reducers';

import { Coach } from '@clemos/models';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { MessageBoxConversationService } from '@clemos/resources';

library.add(faPaperPlane);
library.add(faCoffee);

@Component({
  selector: 'clemos-conversation-detail',
  templateUrl: './conversation-detail.component.html',
  styleUrls: ['./conversation-detail.component.scss']
})
export class ConversationDetailComponent implements OnInit {

  conversation$: Observable<MessageBoxConversation>;
  currentUser$: Observable<Coach>;
  private paramsSubscription: Subscription;
  private conversationSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromRoot.State>,
    private conversationService: MessageBoxConversationService) {
    this.currentUser$ = this.store.pipe(select(fromRoot.getCurrentUser));
  }

  submit() {

  }


  @HostBinding('class.main-content')

  getConversation(id) {
    this.conversation$ = this.conversationService.get({ id: id })
    .pipe(map(res => res['messageBox/conversation']));
  }

  ngOnInit() {
    this.paramsSubscription = this.route.params.subscribe(params => {
      this.getConversation(params['id']);
    });
  }
}
