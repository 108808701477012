import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';

import { MessageBoxRoutingModule } from './message-box-routing.module';
import { ComponentsModule } from './components';

import { ConversationsOverviewComponent } from './containers/conversations-overview/conversations-overview.component';
import { ConversationDetailComponent } from './containers/conversation-detail/conversation-detail.component';
import { ConversationResolver } from './resolvers/conversation-resolver.service';


const CONTAINERS = [
  ConversationsOverviewComponent,
  ConversationDetailComponent
];

const RESOLVERS = [
  ConversationResolver
];

@NgModule({
  imports: [
    MessageBoxRoutingModule,
    SharedModule,
    ComponentsModule
  ],
  declarations: CONTAINERS,
  providers: [
    ...RESOLVERS
  ]
})
export class MessageBoxModule { }
