import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InsideComponent } from '@clemos/jupiter/core/containers/inside/inside.component';
import { AngularTokenService } from 'angular-token';
import { AuthResolver } from '@clemos/jupiter/core/resolvers/auth-resolver.service';
import { LeadsModule } from '../leads/leads.module';
import { AccountModule } from '../account/account.module';
import { CoachesModule } from '../coaches/coaches.module';
import { CustomersModule } from '../customers/customers.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { InstitutionsModule } from '../institutions/institutions.module';
import { InvoicesModule } from '../invoices/invoices.module';
import { MessageBoxModule } from '../message-box/message-box.module';
import { ReportsModule } from '../reports/reports.module';
import { SettingsModule } from '../settings/settings.module';
import { AuthModule } from '../auth/auth.module';

// AngularTokenService.prototype.userSignedIn = function (): boolean {
//   return !!(this.authData && this.userData);
// };

const routes: Routes = [
  {
    path: 'inside',
    component: InsideComponent,
    canActivate: [AngularTokenService],
    resolve: {
      currentUser: AuthResolver
    },
    children: [
      {
        path: 'account',
        loadChildren: () => AccountModule
      },
      {
        path: 'coaches',
        loadChildren: () => CoachesModule
      },
      {
        path: 'customers',
        loadChildren: () => CustomersModule
      },
      {
        path: 'dashboard',
        loadChildren: () => DashboardModule
      },
      {
        path: 'leads',
        loadChildren: () => LeadsModule
      },
      {
        path: 'institutions',
        loadChildren: () => InstitutionsModule
      },
      {
        path: 'invoices',
        loadChildren: () => InvoicesModule
      },
      {
        path: 'message-box',
        loadChildren: () => MessageBoxModule
      },
      {
        path: 'reports',
        loadChildren: () => ReportsModule
      },
      {
        path: 'settings',
        loadChildren: () => SettingsModule
      }
    ]
  },
  {
    path: 'auth',
    loadChildren: () => AuthModule
  },
  {
    path: '',
    redirectTo: '/inside/leads',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
