import { Component, OnInit, Input } from '@angular/core';
import { Lead, Deal } from '@clemos/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { DealService } from '@clemos/resources';

@Component({
  selector: 'clemos-deal-overview',
  templateUrl: './deal-overview.component.html',
  styleUrls: ['./deal-overview.component.scss']
})
export class DealOverviewComponent implements OnInit {

  @Input() lead: Lead;

  leadId: string;
  deals$: Observable<Deal>;

  constructor(
    private route: ActivatedRoute,
    private dealService: DealService) { }

  getDeals() {
    this.deals$ = this.dealService.query({}, { params: { leadId: this.leadId } })
      .pipe(map(res => res.deals));
  }

  ngOnInit() {
    this.leadId = this.route.parent.snapshot.params.id;
    this.getDeals();
  }
}
