import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersComponent } from './customers/customers.component';
import { CustomersRoutingModule } from './customers-routing.module';


export const components = [
  CustomersComponent
];


@NgModule({
  imports: [
    CustomersRoutingModule
  ],
  declarations: components
})
export class CustomersModule {}
