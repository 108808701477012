import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';


@Component({
  selector: 'clemos-lead-object-info',
  templateUrl: './lead-object-info.component.html',
  styleUrls: ['./lead-object-info.component.scss']
})
export class LeadObjectInfoComponent implements OnInit {

  form = new FormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
    },
  };
  fields: FormlyFieldConfig[] = [
    {
      key: 'usage',
      type: 'select',
      templateOptions: {
        label: 'Verwendung',
        options: [
          { value: 'purchase', label: 'Kauf' },
          { value: 'renovation', label: 'Umbau/Renovierung' },
          { value: 'build', label: 'Neubau' },
          { value: 'debt_restructuring', label: 'Umschuldung' },
          { value: 'others', label: 'Sonstige' },
        ]
      },
    },
    {
      key: 'object-type',
      type: 'select',
      templateOptions: {
        label: 'Immobilientyp',
        options: [
          { value: 'plot', label: 'Grundstück' },
          { value: 'condominium', label: 'Eigentumswohnung' },
          { value: 'detached_house', label: 'Einfamilienhaus' },
          { value: 'apartment_house', label: 'Mehrfamilienhus' },
          { value: 'others', label: 'Sonstige' },
        ]
      },
    },
    {
      key: 'type-of-usage',
      type: 'select',
      templateOptions: {
        label: 'Nutzungsart',
        options: [
          { value: 'own use', label: 'Eigennutzung' },
          { value: 'rental', label: 'Vermietung' },
          { value: 'part_rental', label: 'Teilvermietung' }
        ]
      },
    },
    {
      key: 'state',
      type: 'select',
      templateOptions: {
        label: 'Bundesland',
        options: [
          { value: '1', label: 'Wien' },
          { value: '2', label: 'Niederösterreich' },
          { value: '3', label: '...' }
        ]
      }
    },
    {
      key: 'state',
      type: 'input',
      templateOptions: {
        label: 'Ort',
      }
    },
    {
      key: 'state',
      type: 'input',
      templateOptions: {
        label: 'PLZ',
      }
    },
    {
      key: 'privacy',
      type: 'checkbox',
      templateOptions: {
        label: 'mit Markler',
      },
    },
    {
      key: 'state',
      type: 'input',
      templateOptions: {
        label: 'Markler',
      }
    },
    {
      key: 'file',
      type: 'file',
      templateOptions: {
        label: 'Kaufanbot',
      }
    },
    {
      key: 'file',
      type: 'file',
      templateOptions: {
        label: 'Kaufvertrag, -entwurf',
      }
    },
    {
      key: 'file',
      type: 'file',
      templateOptions: {
        label: 'Grundbuchauszug',
      }
    },
    {
      key: 'file',
      type: 'file',
      templateOptions: {
        label: 'Bilder',
      }
    },
    {
      key: 'file',
      type: 'file',
      templateOptions: {
        label: 'Planskizze',
      }
    },
  ];


  constructor() { }

  ngOnInit() {
  }

}
