import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Lead } from '@clemos/models';
import { LeadService } from '@clemos/resources';


@Injectable()
export class LeadResolver implements Resolve<any> {

    constructor(private leadService: LeadService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<Lead> {
        return this.leadService
            .get({ id: route.params.id })
            .pipe(map(res => res['lead']));
    }
}
