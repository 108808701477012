import { FormControl, ValidationErrors } from '@angular/forms';
import { ConfigOption, FormlyFieldConfig } from '@ngx-formly/core';
import { TemplateAddons } from './run/addon';
import {
  FormlyFieldInput,
  FormlyFieldCheckbox,
  FormlyFieldRadio,
  FormlyFieldRadioButtons,
  FormlyFieldSelect,
  FormlyFieldTextArea,
  FormlyFieldMultiCheckbox,
  FormlyFieldCurrency,
  FormlyFieldEmail,
  FormlyFieldDatePicker,
  FormlyFieldFile,
  FormlyFieldPhone
} from './types/types';

import {
  FormlyWrapperAddons,
  FormlyWrapperFormField
} from './wrappers/wrappers';

export const FIELD_TYPE_COMPONENTS = [
  // types
  FormlyFieldInput,
  FormlyFieldCheckbox,
  FormlyFieldRadio,
  FormlyFieldRadioButtons,
  FormlyFieldSelect,
  FormlyFieldTextArea,
  FormlyFieldMultiCheckbox,
  FormlyFieldCurrency,
  FormlyFieldEmail,
  FormlyFieldFile,
  FormlyFieldPhone,
  FormlyFieldDatePicker,

  // wrappers
  FormlyWrapperAddons,
  FormlyWrapperFormField
];

export function IpValidator(control: FormControl): ValidationErrors {
  return !control.value || /(\d{1,3}\.){3}\d{1,3}/.test(control.value)
    ? null
    : { ip: true };
}

export function IpValidatorMessage(err, field: FormlyFieldConfig) {
  return `'${field.formControl.value}' is not a valid IP Address`;
}

export const CUSTOM_FORMLY_CONFIG: ConfigOption = {
  types: [
    {
      name: 'input',
      component: FormlyFieldInput,
      wrappers: ['form-field']
    },
    {
      name: 'checkbox',
      component: FormlyFieldCheckbox,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          indeterminate: true,
          hideLabel: true
        }
      }
    },
    {
      name: 'radio',
      component: FormlyFieldRadio,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'radio-buttons',
      component: FormlyFieldRadioButtons,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'select',
      component: FormlyFieldSelect,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'textarea',
      component: FormlyFieldTextArea,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          cols: 1,
          rows: 1
        }
      }
    },
    {
      name: 'multicheckbox',
      component: FormlyFieldMultiCheckbox,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'currency',
      component: FormlyFieldCurrency,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'email',
      component: FormlyFieldEmail,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'file',
      component: FormlyFieldFile,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'date-picker',
      component: FormlyFieldDatePicker,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    },
    {
      name: 'phone',
      component: FormlyFieldPhone,
      wrappers: ['form-field'],
      defaultOptions: {
        templateOptions: {
          options: []
        }
      }
    }
  ],
  wrappers: [
    { name: 'addons', component: FormlyWrapperAddons },
    { name: 'form-field', component: FormlyWrapperFormField }
  ],
  manipulators: [{ class: TemplateAddons, method: 'run' }],
  validators: [
    { name: 'ip', validation: IpValidator },
  ],
  validationMessages: [
    { name: 'ip', message: IpValidatorMessage },
  ],
};
