import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'clemos-lead-filter',
  templateUrl: './lead-filter.component.html',
  styleUrls: ['./lead-filter.component.scss']
})
export class LeadFilterComponent implements OnInit {

  constructor() { }

  @HostBinding('class.aside')
  @HostBinding('class.aside-expand-md')

  ngOnInit() {
  }

}
