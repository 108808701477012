import {
    CurrentUserActionTypes,
    CurrentUserActionsUnion,
} from '@clemos/jupiter/core/actions/current-user.actions';
import { Coach } from '@clemos/models';

export interface State {
    user?: Coach;
}

const initialState: State = {
};

export function reducer(
    state: State = initialState,
    action: CurrentUserActionsUnion
): State {
    switch (action.type) {
        case CurrentUserActionTypes.LoadCurrentUser:
            return {
                ...state,
                user: action.payload,
            };
        default:
            return state;
    }
}

export const getUser = (state: State) => state.user;
