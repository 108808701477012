import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClemosPipesModule } from '@clemos/pipes';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { FormlyModule } from '@ngx-formly/core';

import { MomentModule } from 'ngx-moment';

// ngx-perfect-scrollbar
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// Directives
import { DoFloatDirective } from './directives/do-float.directive';
import { AsideTogglerComponent } from '@clemos/jupiter/shared/components/aside-toggler.component';
import { QuickviewComponent } from '@clemos/jupiter/shared/components/quickview/quickview.component';
import { TranslateModule } from '@ngx-translate/core';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const SHARED_MODULES = [
  CommonModule,
  FormsModule,
  ClemosPipesModule,
  ReactiveFormsModule,
  FormlyModule,
  FontAwesomeModule,
  NgbModule,
  NgSelectModule,
  MomentModule,
  PerfectScrollbarModule,
  NgxDatatableModule,
  TranslateModule,
];

const COMPONENTS = [
  AsideTogglerComponent,
  QuickviewComponent
];

const PIPES = [
];

const DIRECTIVES = [
  DoFloatDirective
];

@NgModule({
  imports: [
    ...SHARED_MODULES
  ],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES
  ],
  exports: [
    ...SHARED_MODULES,
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES
  ]
})
export class SharedModule {}
