import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clemos-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
