import { Component, OnInit, Input } from '@angular/core';
import { MessageBoxConversation } from '@clemos/models';

@Component({
  selector: 'clemos-conversation-list',
  templateUrl: './conversation-list.component.html',
  styleUrls: ['./conversation-list.component.scss']
})
export class ConversationListComponent implements OnInit {

  @Input() conversations: MessageBoxConversation[];

  constructor() { }

  getAuthor(conversation) {
    // const type = conversation.author.type;
    // return conversation.author[type];
  }

  getConversationPartner(conversation) {
    let type = '';
    switch (conversation.conversationType) {
      case 'customer': {
         type = 'customer';
        break;
      }
      case 'support': {
         type = 'institution/user';
        break;
      }
      default: {

      }
    }

    for (const participant of conversation.participants) {
      if (participant.user.type === type) {
        return participant.user[type];
      }
    }
  }

  ngOnInit() {
  }
}
