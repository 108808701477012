import { NgModule } from '@angular/core';
import { ReportsComponent } from './reports/reports.component';
import { ReportsRoutingModule } from './reports-routing.module';

const components = [
  ReportsComponent
];

@NgModule({
  imports: [
    ReportsRoutingModule
  ],
  declarations: components
})
export class ReportsModule { }
