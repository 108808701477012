import { Component, OnInit, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import * as LayoutActions from '@clemos/jupiter/core/actions/layout.actions';
import * as fromRoot from '@clemos/jupiter/reducers';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

library.add(faTimes);

@Component({
  selector: 'clemos-quickview',
  templateUrl: './quickview.component.html',
  styleUrls: ['./quickview.component.scss']
})
export class QuickviewComponent implements OnInit {

  @Input() size: String = 'lg';

  showQuickview$: Observable<boolean>;


  constructor(
    private store: Store<fromRoot.State>) {
    this.showQuickview$ = this.store.pipe(select(fromRoot.getShowQuickview));
  }

  closeQuickview() {
    this.store.dispatch(new LayoutActions.CloseQuickview());
  }

  ngOnInit() {
  }

}
