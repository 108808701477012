// Angular core modules
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// App components
import { AuthComponent } from './containers/auth.component';
import { SignInComponent } from './components/sign-in/sign-in.component';


export const routes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children: [
            { path: '', redirectTo: 'sign-in', pathMatch: 'full' },
            { path: 'sign-in', component: SignInComponent },
        ]
    }
];


@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})
export class AuthRoutingModule { }
