import { NgModule } from '@angular/core';


import { AuthComponent } from './containers/auth.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';

const components = [
  AuthComponent,
  SignInComponent
];


@NgModule({
  imports: [
    AuthRoutingModule,
    SharedModule
  ],
  declarations: components
})
export class AuthModule {}


